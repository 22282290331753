.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.screen {
  height: 600px;
  width: 800px;
  padding-top: 20px;
}

@media only screen and (max-width: 768px) {
  .screen {
    height: 400px;
    width: 80%;
    padding-top: 20px;
  }
}

.react-dropdown-select-clear,
.react-dropdown-select-dropdown-handle {
  color: #fff;
}
.react-dropdown-select-option {
  border: 1px solid #282c34;
}
.react-dropdown-select-item {
  color: #333;
}
.react-dropdown-select-input {
  color: #282c34;
  width: 10px;
}

.react-dropdown-select-item {
  color: #fff;
  background: #282c34;
  border-bottom: 1px solid #333;
}
.react-dropdown-select-item:hover {
    color: #282c34 !important;
}
.react-dropdown-select-item.react-dropdown-select-item-selected,
.react-dropdown-select-item.react-dropdown-select-item-active {
  background: #111 !important;
  border-bottom: 1px solid #333 !important;
  background: #55575b !important;
  font-weight: bold !important;
}

.react-dropdown-select-dropdown {
  max-height: 80vh !important;
}